import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from 'src/app/modules/app-material.module';

@Component({
  selector: 'app-empty-placeholder',
  standalone: true,
  imports: [CommonModule, AppMaterialModule],
  templateUrl: './empty-placeholder.component.html',
  styleUrls: ['./empty-placeholder.component.scss'],
})
export class EmptyPlaceholderComponent {}
